import { Reservation } from "@/types/Reservation";
import { format, set } from "date-fns";

export const SECOND = 1000;

export const MINUTE = SECOND * 60;

export const HOUR = MINUTE * 60;

export const DAY = HOUR * 24;

export const getTimeDiff = (date1: Date, date2: Date): number => {
  return date1.getTime() - date2.getTime();
};

export const getNumberOfSeconds = (time: number): number => {
  return Math.floor(time / SECOND);
};

export const getMinutesWithoutHours = (time: number): number => {
  return Math.floor((time % HOUR) / MINUTE);
};

export const getNumberOfMinutes = (time: number): number => {
  return Math.floor(time / MINUTE);
};

export const getNumberOfHours = (time: number): number => {
  return Math.floor(time / HOUR);
};

export const getNumberOfDays = (time: number): number => {
  const retv = Math.floor(time / DAY);
  return retv;
};

export const getMsTillMidnight = (timestamp: number): number => {
  return DAY - (timestamp - set(timestamp, { hours: 0, minutes: 0, seconds: 0 }).getTime());
};

export const getDayDifference = (start: Date, end: Date): number => {
  const date1 = new Date(format(start, "P"));
  const date2 = new Date(format(end, "P"));

  const diffInTime = getTimeDiff(date2, date1);

  const diffInDays = Math.floor(diffInTime / DAY);

  return diffInDays;
};

export const getRefreshTimestamp = (date: Date): Date => {
  return set(date, { hours: 0, minutes: 0, seconds: 5, milliseconds: 0 });
};

export const remainingTimeOfReservationInfo = (
  firstFutureReservation: Reservation,
  now: Date
): { diffHours: number | null; diffMinutes: number | null } => {
  if (!firstFutureReservation) {
    return { diffHours: null, diffMinutes: null };
  }
  const timeDiff = getTimeDiff(firstFutureReservation.startsAt, now) + MINUTE;
  const diffHours = getNumberOfHours(timeDiff);
  const diffMinutes = getMinutesWithoutHours(timeDiff);

  return { diffHours, diffMinutes };
};
