import pckg from "../../../package.json";
import { Logtail } from "@logtail/browser";
import { LogLevel } from "@logtail/types";
import { useProfileStore } from "@/store/Profile";

type Severity = "info" | "warn" | "debug" | "error";

export interface HandlerI<T> {
  openDBConnection(): void;
  closeDBConnection(): Promise<void>;
  deleteDatabase(): Promise<void>;
}

interface LoggingSchemaValue {
  created: Date;
  severity: Severity;
  location: string;
  appVersion: string;
  message: string;
}

class LoggingService {
  logger: Logtail | null;

  constructor() {
    this.logger = null;
  }

  initializeLogger(logtailToken: string) {
    this.logger = new Logtail(logtailToken);
  }

  info(location: string, message: string) {
    this.log(location, message, LogLevel.Info);
  }

  debug(location: string, message: string) {
    this.log(location, message, LogLevel.Debug);
  }

  warn(location: string, message: string) {
    this.log(location, message, LogLevel.Warn);
  }

  error(location: string, message: string) {
    this.log(location, message, LogLevel.Error);
  }

  private log(location: string, message: string, severity: LogLevel) {
    const appVersion = this.getAppVersion();
    console[severity](this.formatLog({ message, severity, location, created: new Date(), appVersion }));

    if (!this.logger) return;

    const profile = useProfileStore();

    // Add additional tags/breadcrums here
    // TODO: Construct it in a way that doesn't use non-null assertion
    this.logger.log(message, severity, {
      location,
      appVersion,
      organizationName: profile.organization!.name,
      organizationId: profile.organization!.id,
      resourceName: profile.resource!.name,
      resourceId: profile.resource!.id,
      tabletId: profile.tabletInfo!.id,
    });
  }

  private formatLog({ message, severity, location, created, appVersion }: LoggingSchemaValue): string {
    return `${created} | ${severity} | ${location} | ${appVersion} | ${message}`;
  }

  private getAppVersion() {
    return pckg.version;
  }
}

const loggingService = new LoggingService();

export default loggingService;
