import axios, { AxiosInstance } from "axios";
import loggingService from "../LoggingService";
import * as Sentry from "@sentry/vue";
import { pairPath } from "@/router/paths";
import router from "@/router";

export const httpClient = (): any => {
  const httpClient: AxiosInstance = axios.create({
    baseURL: process.env.BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  httpClient.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status >= 500) {
        const { config } = error;

        // If config does not exist or the retry option is not set, reject
        if (!config || !config.retry) {
          return Sentry.captureException(error);
        }

        // Set the variable for the maximum number of retries
        config.retryCount = config.retryCount || 0;

        // Check if we've maxed out our retry count
        if (config.retryCount >= config.retry.maxRetryCount) {
          return Sentry.captureException(error);
        }

        // Increase the retry count
        config.retryCount += 1;
        const delayTime = config.retry.delay * Math.pow(config.retry.backoff, config.retryCount);

        // Return a promise to retry the request
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(httpClient(config));
          }, delayTime);
        });
      } else {
        if (error.response.status === 401 && !window.location.href.endsWith(pairPath)) {
          loggingService.warn("Home.vue", `❗️ Tablet unpaired: ${error}`);
          window.location.href = window.location.origin + "/#" + pairPath;
          window.location.reload();
          return;
        }
        throw error;
      }
    }
  );
  return httpClient;
};

export default httpClient;
