import { defineStore } from "pinia";
import { httpService } from "@/services/HttpService";
import loggingService from "@/services/LoggingService";
import { ResourceDTO, OrganizationDTO, ConfigDTO, PusherDTO, TabletDTO, AvailabilityDTO } from "@/dto/profile";
import { DAY } from "@/helpers/Time";
import { addDays, set } from "date-fns";
import router from "@/router";
import { Howl } from "howler";
import { disconnectedPath } from "@/router/paths";
import { Msg } from "@/services/PusherService";
import { useScheduleStore } from "@/store/Schedule";
import { format } from "date-fns";

interface InitialState {
  resource: null | ResourceDTO;
  pusher: null | PusherDTO;
  organization: null | OrganizationDTO;
  config: null | ConfigDTO;
  connected: boolean;
  disconnectedTimer: ReturnType<typeof setTimeout> | null;
  audioCtx: AudioContext | null;
  tablet: null | TabletDTO;
  availability: AvailabilityDTO | null;
}

export const useProfileStore = defineStore("profile", {
  state: (): InitialState => ({
    resource: null,
    pusher: null,
    organization: null,
    config: null,
    connected: true,
    disconnectedTimer: null,
    audioCtx: null,
    tablet: null,
    availability: null,
  }),
  actions: {
    startPinging(interval: number) {
      setInterval(() => httpService.ping(), interval);
      loggingService.info("profile.ts", "Pinging initialized");
      httpService.ping();
    },
    async pairDevice(pinCode: number[]) {
      const response = await httpService.pairDevice(pinCode);
      if (response.data) {
        loggingService.info("profile.ts", "Paired device");
      }

      return response;
    },
    initializeAudio() {
      const Context = window.AudioContext || window.webkitAudioContext || false;
      if (!Context) {
        loggingService.warn("Profile.ts", "🔊 Web Audio API is not supported by your browser");
        return;
      }
      this.audioCtx = new Context();
      // Queue silent sound to unlock WebAPI and AutoPlay sounds after touchend
      const sound = new Howl({
        src: require("@/assets/Sounds/silence.mp3"),
        onplayerror: function () {
          sound.once("unlock", function () {
            sound.play();
          });
        },
      });

      sound.play();
    },
    async getProfile() {
      const { data, error } = await httpService.getProfile();

      if (error || !data) {
        loggingService.error("profile.ts", `❗️ Failed to get profile ${error}`);
        return { error, data };
      }

      this.$patch(state => {
        const { profile } = data;
        state.resource = profile.resource;
        state.organization = profile.organization;
        state.pusher = profile.pusher;
        state.config = profile.config;
        state.tablet = profile.tablet;
        state.availability = profile.config.availability;
      });

      if (data.profile.logtailToken) loggingService.initializeLogger(data.profile.logtailToken);

      return { error, data };
    },
    async connect() {
      const scheduleStore = useScheduleStore();
      await scheduleStore.refreshTablet();
      this.connected = true;
      window.clearTimeout(this.disconnectedTimer as unknown as number);
      loggingService.info("profile.ts", "📶 Connected to pusher");
      this.disconnectedTimer = null;
    },
    disconnect() {
      const msTillMidnight = DAY - (Date.now() - set(Date.now(), { hours: 0, minutes: 0, seconds: 0 }).getTime());
      this.disconnectedTimer = setTimeout(() => {
        const path = disconnectedPath;
        loggingService.debug("ProfileStore.ts", `🛣️ Changing route to ${path}`);
        router.push(path);
      }, msTillMidnight);
      loggingService.warn("profile.ts", "📶 Disconnected from pusher");
      this.connected = false;
    },
    pusherAck(eventName: string, m: Msg) {
      if (!eventName.startsWith("pusher:")) {
        httpService.pusherAck(m);
      }
    },
  },
  getters: {
    isPaired: state => !!state.pusher,
    checkInRequired: state => state.config?.checkinRequired,
    timeUntilAutoCancel: state => {
      // TODO: Fix initial state so typescript does not return possible undefined
      if (!state.config) return 5;
      return state.config.timeUntilAutoCancel;
    },
    isConnected: state => state.connected,
    language: state => state.config?.language,
    resourceName: state => state.resource?.name || "",
    tabletTimeLimit: state => state.config?.timeLimitForTabletReservations,
    webTimeLimit: state => state.config?.timeLimitForWebappReservations,
    allowForLocalReservation: state => state.config?.allowLocalReservation || false,
    shouldPlaySound: state => state.config?.shouldPlaySound,
    checkinAudioReminderTime: state => state.config?.checkinAudioReminderTime,
    endtimeAudioReminderTime: state => state.config?.endtimeAudioReminderTime,
    qrOnTablets: state => state.config?.qrOnTablets,
    tabletInfo: state => state.tablet,
    calculateAvailability: state => (date: Date) => {
      if (!state.availability) return [];
      const AHEAD = 8;
      const retv: Date[][] = [];
      const startDay = date.getDay();
      const startDate = new Date(format(date, "P"));

      for (let i = 0; i < AHEAD; i++) {
        const dayOfTheWeek = ((startDay + i) % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6;
        const day = addDays(startDate, i);

        const pattern = state.availability.pattern[dayOfTheWeek];

        if (pattern === null) continue;

        const { start, end } = pattern;

        let businessHoursStart: Date;
        let businessHoursEnd: Date;

        if (start === null || end === null) {
          businessHoursStart = new Date(day);
          businessHoursEnd = addDays(businessHoursStart, 1);
        } else {
          const wHStart = start.split(":");
          const wHEnd = end.split(":");

          businessHoursStart = set(day, {
            hours: parseInt(wHStart[0]),
            minutes: parseInt(wHStart[1]),
            milliseconds: 0,
          });

          businessHoursEnd = set(day, {
            hours: parseInt(wHEnd[0]),
            minutes: parseInt(wHEnd[1]),
            milliseconds: 0,
          });
        }

        retv.push([businessHoursStart, businessHoursEnd]);
      }
      return retv;
    },
    isAvailable() {
      return (date: Date) => {
        const availability = this.calculateAvailability(date);
        return availability.some(hoursSet => hoursSet[0] <= date && hoursSet[1] >= date);
      };
    },
    nextAvailableTime() {
      return (date: Date) => {
        const availability = this.calculateAvailability(date);
        for (const available of availability) {
          if (available[0] > date) {
            return available[0];
          }
        }
      };
    },
  },
});
