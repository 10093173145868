<template>
  <div class="overlay h-full w-full fixed z-10">
    <div class="loader w-40 h-40 m-auto absolute inset-0 inline-block">
      <div class="w-40 h-40 absolute block"></div>
      <div class="w-40 h-40 absolute block"></div>
      <div class="w-40 h-40 absolute block"></div>
      <div class="w-40 h-40 absolute block"></div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
<style>
.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
.loader > div {
  border: 1rem solid;
  border-radius: 50%;
  border-bottom-color: "#000000";
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.loader > div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader > div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader > div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
