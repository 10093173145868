import { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import httpClient from "./api";

interface RetryDetailsConfig {
  maxRetryCount: number;
  delay: number;
  backoff: number;
}

interface RetryConfig extends AxiosRequestConfig {
  retry: RetryDetailsConfig;
  retryCount: number;
}

export class BaseService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = httpClient();
  }

  protected async axiosCall<T, E = any>(
    config: AxiosRequestConfig
  ): Promise<{ error: AxiosError<E> | null; data?: T | null }> {
    try {
      const retry = {
        maxRetryCount: 2,
        delay: 1000,
        backoff: 2,
      };
      const configWithRetry = { ...config, retry } as RetryConfig;
      const { data } = await this.axiosInstance.request<T>(configWithRetry);
      return { error: null, data };
    } catch (error: unknown) {
      const err = error as AxiosError<E>;
      return { error: err, data: null };
    }
  }
}
