import Pusher from "pusher-js";
import * as PusherTypes from "pusher-js";
import { useProfileStore } from "@/store/Profile";
import { useScheduleStore } from "@/store/Schedule";
import loggingService from "@/services/LoggingService";
import * as Sentry from "@sentry/vue";
import i18n from "@/locale";

export interface Msg {
  msgId: string;
}

export interface Upgrade {
  url: string | null | undefined;
}

export class PusherServiceClass {
  private pusherInstance: Pusher | undefined;
  private resourceChannel: PusherTypes.Channel | undefined;
  private organizationChannel: PusherTypes.Channel | undefined;

  initialize(): void {
    const profileStore = useProfileStore();

    if (
      !profileStore.pusher ||
      !profileStore.pusher.appKey ||
      !profileStore.pusher.resourceChannel ||
      !profileStore.pusher.organizationChannel ||
      !profileStore.pusher.presenceChannel
    ) {
      loggingService.error("Pusher Initialize", "❗️ Failed to receive pusher data");
      return;
    }

    if (profileStore.pusher.verboseLogging) {
      Pusher.logToConsole = true;
      Pusher.log = (message: string) => {
        loggingService.info("Pusher Logger", `🤖 ${message}`);
      };
    }
    this.pusherInstance = new Pusher(profileStore.pusher.appKey, {
      cluster: "eu",
      // TODO: Change this from hardcoded value
      authEndpoint: `/pusher/auth`,
    });

    loggingService.debug("PusherService.ts", "Initialized connection with pusher");

    this.resourceChannel = this.pusherInstance.subscribe(profileStore.pusher.resourceChannel);
    this.organizationChannel = this.pusherInstance.subscribe(profileStore.pusher.organizationChannel);
    this.pusherInstance.subscribe(profileStore.pusher.presenceChannel);

    loggingService.debug("PusherService.ts", "Subscribed to private and presence channels");

    if (profileStore.pusher.ackEnabled) {
      this.resourceChannel.bind_global(profileStore.pusherAck);
      this.organizationChannel.bind_global(profileStore.pusherAck);
    }

    const scheduleStore = useScheduleStore();

    this.resourceChannel.bind("update-schedule", scheduleStore.refreshTablet);
    this.resourceChannel.bind("update-profile", profileStore.getProfile);
    this.resourceChannel.bind("unpair", () => {
      loggingService.info("PusherService.ts", `🔚 Unpairing`);
      window.location.reload();
    });

    this.resourceChannel.bind("dump-sentry", () => {
      Sentry.withScope(function (scope) {
        scope.setFingerprint([String(new Date())]);
        Sentry.captureMessage("Sentry dump");
      });
    });

    this.organizationChannel.bind("update-settings", async () => {
      const { data } = await profileStore.getProfile();
      const lang = data?.profile.config.language || "en";

      i18n.global.locale = lang;
    });

    this.pusherInstance.connection.bind("connected", profileStore.connect);
    this.pusherInstance.connection.bind("unavailable", profileStore.disconnect);
    this.pusherInstance.connection.bind("connecting", profileStore.disconnect);

    this.resourceChannel.bind("upgrade", (data: Upgrade) => {
      if (!data.url) {
        loggingService.info("PusherService.ts", `♺ Reloading page`);
        window.location.reload();
        return;
      }
      loggingService.info("PusherService.ts", `🆕 Upgrading to: ${data.url}`);
      window.location.replace(data.url);
    });
  }
}

const pusherService = new PusherServiceClass();

export default pusherService;
