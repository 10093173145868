import reservationStatus, { castReservation, getReservationsFinishingInFuture } from "@/helpers/Reservations";
import httpService from "@/services/HttpService";
import { defineStore } from "pinia";
import { useProfileStore } from "../Profile";
import loggingService from "@/services/LoggingService";
import { convertFromDTO } from "@/helpers/Schedule/index";
import { MODE } from "@/types/Schedule";
import { CreatedVia, Reservation } from "@/types/Reservation";

interface InitialState {
  time: Date;
  reservations: Reservation[];
}

export const useScheduleStore = defineStore("schedule", {
  state: (): InitialState => {
    return {
      time: new Date(),
      reservations: [],
    };
  },
  actions: {
    startTime() {
      setInterval(() => (this.time = new Date()), 1000);
    },
    async refreshTablet() {
      loggingService.info("Schedule.ts", "♻️ Fetching schedule");
      const { error, schedule } = await httpService.getSchedule();

      if (error || !schedule) {
        loggingService.error("Schedule.ts", `❗ Failed to fetch schedule: ${error}`);
        return { error, schedule };
      }

      const { reservations } = convertFromDTO(schedule);

      this.reservations = reservations;

      this.time = new Date();

      return { error, schedule };
    },
    async useNowResource() {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return httpService.useNowResource(tz);
    },
    async endInProgressReservation() {
      const reservation = this.firstFutureReservation;

      if (this.getScreenMode !== MODE.INPROGRESS) {
        loggingService.warn("Schedule.ts", "Cannot end reservation which is not in progress");
        return { error: "" };
      }

      const response = await httpService.endReservation(reservation.id);
      if (!response.error) {
        this.reservations = this.reservations.filter(e => e.id != reservation.id);
      }
      return response;
    },
    async checkIn() {
      const reservation = this.firstFutureReservation;

      if (this.getScreenMode !== MODE.COMINGUP) {
        loggingService.warn("Schedule.ts", "Cannot checkin reservation which is not coming up");
        return { error: null, data: null };
      }

      const { error, data } = await httpService.checkIn(reservation.id);

      if (data) {
        this.reservations = this.reservations.map(item => (item.id === data.id ? castReservation(data) : item));
      }

      return { error, data };
    },
    addTime() {
      const reservation = this.firstFutureReservation;

      if (this.getScreenMode !== MODE.INPROGRESS) {
        loggingService.warn("Schedule.ts", "Cannot add time to reservation which has not begun");
        return { error: "" };
      }

      return httpService.addTime(reservation.id);
    },
  },
  getters: {
    getTime: state => {
      return state.time;
    },
    getReservations: state => {
      return state.reservations;
    },
    futureReservations(): Reservation[] {
      return getReservationsFinishingInFuture(this.reservations, this.getTime.getTime());
    },
    firstFutureReservation(): Reservation {
      return this.futureReservations[0];
    },
    upcomingReservations(): Reservation[] {
      return this.futureReservations.filter(reservation => {
        if (reservation.startsAt > this.getTime) {
          if (reservationStatus.isComingUp(reservation)) {
            if (this.getScreenMode === MODE.INPROGRESS) {
              return true;
            }

            if (this.getScreenMode === MODE.COMINGUP) {
              return false;
            }
          }

          return true;
        } else {
          return false;
        }
      });
    },
    nearestUpcomingReservation(): Reservation {
      return this.upcomingReservations[0];
    },
    getScreenMode() {
      const firstEvent = this.firstFutureReservation;
      const profileStore = useProfileStore();
      const now = this.getTime;

      const isAvailable = profileStore.isAvailable(now);

      if (!isAvailable && !firstEvent) return MODE.UNAVAILABLE;

      if (isAvailable && !firstEvent) return MODE.AVAILABLE;

      if (!profileStore.checkInRequired) {
        if (reservationStatus.isInProgress(firstEvent, now)) {
          return MODE.INPROGRESS;
        }

        if (reservationStatus.isComingUp(firstEvent, now)) {
          return MODE.COMINGUP;
        }
      } else {
        if (
          (firstEvent.createdVia === CreatedVia.TABLET ||
            firstEvent.createdVia === CreatedVia.ADHOC ||
            firstEvent.checkedIn) &&
          reservationStatus.isInProgress(firstEvent, now)
        ) {
          return MODE.INPROGRESS;
        } else if (reservationStatus.isComingUp(firstEvent, now) || reservationStatus.isPostComingUp(firstEvent, now)) {
          return MODE.COMINGUP;
        }
      }

      if (!isAvailable) {
        return MODE.UNAVAILABLE;
      }

      return MODE.AVAILABLE;
    },
  },
});
