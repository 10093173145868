import { ReservationDTO } from "@/dto/reservation";
import { ScheduleDTO } from "@/dto/schedule";
import axios, { AxiosError } from "axios";
import { ProfileDTO } from "@/dto/profile";
import { LogsUrlDTO } from "@/dto/logsUrl";
import { Msg } from "@/services/PusherService";

import loggingService from "@/services/LoggingService";
import { BaseService } from "../base";

class HttpService extends BaseService {
  async getSchedule(): Promise<{ error: AxiosError | null; schedule: ScheduleDTO | null }> {
    const { error, data } = await this.axiosCall<{
      schedule: ScheduleDTO;
    }>({
      method: "get",
      url: `api/schedule`,
      params: { time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
    if (!data) return { error, schedule: null };
    return { error, schedule: data.schedule };
  }

  pairDevice(pinCode: number[]): Promise<{ error: AxiosError | null; data?: null }> {
    return this.axiosCall<null>({ method: "post", url: "/api/pairing", data: { pin: pinCode.join("") } });
  }

  getProfile(): Promise<{ error: AxiosError | null; data?: { profile: ProfileDTO } | null }> {
    return this.axiosCall<{ profile: ProfileDTO }>({ method: "get", url: "/api/profile" });
  }

  getSendLogsUri(fileName: string): Promise<{ error: AxiosError | null; data?: LogsUrlDTO | null }> {
    return this.axiosCall<LogsUrlDTO>({ method: "get", url: "/api/presigned_url", params: { object_name: fileName } });
  }

  sendLogs(uri: string, logs: File) {
    return axios.put(uri, logs);
  }

  pusherAck(message: Msg) {
    return axios.post("/pusher/ack", { msgId: message.msgId });
  }

  endReservation(reservationId: number): Promise<{ error: AxiosError | null }> {
    return this.axiosCall({ method: "post", data: { reservationId }, url: "/api/end_now" });
  }

  useNowResource(tz: string): Promise<{ error: AxiosError | null }> {
    return this.axiosCall({ method: "post", data: { tz }, url: "/api/use_now" });
  }

  checkIn(reservationId: number): Promise<{ error: AxiosError | null; data?: ReservationDTO | null }> {
    return this.axiosCall<ReservationDTO>({ method: "POST", url: "/api/check_in", data: { reservationId } });
  }

  addTime(reservationId: number): Promise<{ error: AxiosError | null }> {
    return this.axiosCall({ method: "POST", url: "/api/add_time", data: { reservationId } });
  }

  async ping() {
    const { error } = await this.axiosCall({ method: "PUT", url: "/api/ping" });
    if (error) {
      loggingService.error("HttpService.ts", `❗️ Failed to ping ${error}`);
    }
  }
}

export const httpService = new HttpService();
export default httpService;
