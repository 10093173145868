import { ScheduleDTO } from "@/dto/schedule";
import { activeHours, Schedule } from "@/types/Schedule";
import { castReservations } from "@/helpers/Reservations";

export const convertFromDTO = (scheduleDTO: ScheduleDTO): Schedule => {
  const { reservations } = scheduleDTO;

  return {
    reservations: castReservations(reservations),
  };
};

export const convertNextBusinessDay = (nextBusinessDayDTO: string | null): Date | null => {
  return nextBusinessDayDTO ? new Date(nextBusinessDayDTO) : null;
};

export const mapActiveHoursToDateRanges = (timeRanges: string[][]): activeHours[] => {
  return timeRanges.map(timeRange => timeRange.map(hours => new Date(hours)));
};
