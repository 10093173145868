import { getDayDifference } from "@/helpers/Time";
import { Reservation } from "@/types/Reservation";
import { format } from "date-fns";
import { computed, ComputedRef, ref, Ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

interface reservationHook {
  startTime: Ref<string>;
  endTime: Ref<string>;
  pastDays: Ref<string>;
  futureDays: Ref<string>;
  reservationTitle: ComputedRef<string>;
  pending: Ref<null | boolean>;
}

export const useReservation = (reservation: Ref<Reservation>): reservationHook => {
  const { t } = useI18n();

  const endTime = ref("");
  const startTime = ref("");

  const pastDays = ref("");
  const futureDays = ref("");

  const reservationTitle = computed(() => {
    return reservation.value.title ? reservation.value.title : t("noTitleFallback");
  });

  const pending = ref();

  function calculate() {
    if (!reservation.value) return;

    startTime.value = format(reservation.value.startsAt, "p");
    endTime.value = format(reservation.value.finishesAt, "p");

    const startDelta = getDayDifference(new Date(), reservation.value.startsAt);
    const endDelta = getDayDifference(new Date(), reservation.value.finishesAt);

    pastDays.value = startDelta !== 0 ? `${startDelta}d` : "";
    futureDays.value = endDelta !== 0 ? `+${endDelta}d` : "";

    pending.value = reservation.value.pending;
  }

  watchEffect(calculate);

  return {
    startTime,
    endTime,
    pastDays,
    futureDays,
    reservationTitle,
    pending,
  };
};
