import { library } from "@fortawesome/fontawesome-svg-core";

import { faBackspace, faExclamationCircle, faTimes, faUserSecret } from "@fortawesome/free-solid-svg-icons";

import {
  faHourglassStart as falHourglassStart,
  faCheck as falCheck,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";

library.add(faUserSecret);
library.add(faTriangleExclamation);
library.add(faExclamationCircle);
library.add(faTimes);
library.add(faBackspace);
library.add(falHourglassStart);
library.add(falCheck);
