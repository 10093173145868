/* eslint-disable @typescript-eslint/no-var-requires */
import { useProfileStore } from "@/store/Profile";
import { Ref, ref } from "vue";
import { Howl } from "howler";

type Sound = "checkin" | "ending";

const CheckinSound: string = require("@/assets/Sounds/notification.mp3");
const EndingSound: string = require("@/assets/Sounds/notification.mp3");

interface SoundHook {
  soundPlayed: Ref<boolean>;
  playSound: () => void;
}

const sounds = {
  checkin: CheckinSound,
  ending: EndingSound,
};

export const useSound = (soundToPlay: Sound): SoundHook => {
  const soundPlayed = ref(false);

  async function playSound() {
    if (!soundPlayed.value && useProfileStore().shouldPlaySound) {
      const sound = new Howl({ src: sounds[soundToPlay] });
      sound.play();
      soundPlayed.value = true;
    }
  }

  return {
    soundPlayed,
    playSound,
  };
};

export default useSound;
