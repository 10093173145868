import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import "./assets/index.css";
import "./plugins/Fontawesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import i18n from "@/locale";
import sentryPlugin from "./plugins/Sentry";

const app = createApp(App);

sentryPlugin.addApp(app);

app.component("FontAwesomeIcon", FontAwesomeIcon);
app.use(i18n);
app.use(createPinia());
app.use(router);
app.mount("#app");
