import { Reservation } from "../Reservation";

export type activeHours = Date[];

export interface Schedule {
  reservations: Reservation[];
}

export enum MODE {
  AVAILABLE = "available",
  INPROGRESS = "inprogress",
  COMINGUP = "comingup",
  UNAVAILABLE = "unavailable",
}
