import { App } from "vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from "@/router";
import { ProfileDTO } from "@/dto/profile";
import pckg from "../../../package.json";

class SentryPlugin {
  private app: App | undefined;

  addApp(app: App) {
    this.app = app;
  }

  initializeSentry(dsn: string, profile: ProfileDTO): void {
    Sentry.init({
      app: this.app,
      dsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [window.location.origin, /^\//],
        }),
      ],
      tracesSampleRate: 0.7,
    });

    const { resource, organization, tablet } = profile;

    Sentry.setUser({ id: `${tablet.id}` });

    Sentry.setTag("Roomzilla.version", pckg.version);
    Sentry.setTag("Roomzilla.resource.id", resource.id);
    Sentry.setTag("Roomzilla.resource.name", resource.name);
    Sentry.setTag("Roomzilla.organization.id", organization.id);
    Sentry.setTag("Roomzilla.organization.name", organization.name);
  }
}

export const sentryPlugin = new SentryPlugin();

export default sentryPlugin;
