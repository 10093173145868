import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Pair from "../views/Pair.vue";
import Disconnected from "../views/Disconnected.vue";
import { useProfileStore } from "../store/Profile";
import homePath, { disconnectedPath, pairPath } from "./paths";
import sentryPlugin from "@/plugins/Sentry";
import i18n from "@/locale";

export const routes: Array<RouteRecordRaw> = [
  {
    path: homePath,
    name: "Home",
    component: Home,
    beforeEnter: async (): Promise<RouteLocationRaw | boolean> => {
      const pairingStore = useProfileStore();
      const { data } = await pairingStore.getProfile();

      if (!pairingStore.isPaired) {
        return { name: "Pair" };
      }

      if (data?.profile.sentryDsn) sentryPlugin.initializeSentry(data.profile.sentryDsn, data.profile);

      const lang = data?.profile.config.language || "en";
      i18n.global.locale = lang;

      return true;
    },
  },
  {
    path: pairPath,
    name: "Pair",
    component: Pair,
    beforeEnter: async (): Promise<RouteLocationRaw | boolean> => {
      const pairingStore = useProfileStore();
      await pairingStore.getProfile();
      i18n.global.locale = "en";

      if (pairingStore.isPaired) {
        return { name: "Home" };
      }
      return true;
    },
  },
  {
    path: disconnectedPath,
    name: "Disconnected",
    component: Disconnected,
    beforeEnter: (): RouteLocationRaw | boolean => {
      const pairingStore = useProfileStore();

      if (pairingStore.isConnected) {
        return { name: "Home" };
      }

      i18n.global.locale = "en";

      return true;
    },
  },
];

export default routes;
