import { ReservationDTO } from "@/dto/reservation";
import { Reservation } from "@/types/Reservation";
import { subMinutes, subSeconds } from "date-fns";

export const getReservationsFinishingInFuture = (reservations: Reservation[], now: number): Reservation[] => {
  // stop showing reservation request by 1 second less
  return reservations.filter(
    item => !(item.pending === true && item.startsAt.getTime() - 1000 < now) && item.finishesAt.getTime() > now
  );
};

export class ReservationStatus {
  isInProgress(reservation: Reservation, time = new Date()): boolean {
    return subSeconds(reservation.startsAt, 1) <= time && reservation.finishesAt > time;
  }

  isComingUp(reservation: Reservation, time = new Date()): boolean {
    return subMinutes(reservation.startsAt, 5) <= time && reservation.startsAt > time && reservation.finishesAt > time;
  }

  isPostComingUp(reservation: Reservation, time = new Date()): boolean {
    return !reservation.checkedIn && reservation.startsAt < time && reservation.finishesAt > time;
  }

  isUpcoming(reservation: Reservation, time = new Date()): boolean {
    return subMinutes(reservation.startsAt, 5) > time;
  }
}

export const castReservations = (reservationsDTO: ReservationDTO[]): Reservation[] => {
  return reservationsDTO.map(item => {
    return castReservation(item);
  });
};

export const castReservation = (reservation: ReservationDTO): Reservation => {
  const { id, finishesAt, startsAt, title, checkedIn, createdVia, pending } = reservation;
  return {
    id,
    title,
    finishesAt: new Date(finishesAt),
    startsAt: new Date(startsAt),
    checkedIn,
    createdVia,
    pending,
  };
};

export const getReservationSize = (duration: number): string => {
  if (duration <= 30) {
    return "sm";
  } else if (duration > 30 && duration <= 60) {
    return "basic";
  } else if (duration > 60 && duration <= 120) {
    return "lg";
  } else {
    return "xl";
  }
};

export const reservationStatus = new ReservationStatus();

export default reservationStatus;
