import en from "./en.json";
import ja from "./ja.json";
import { createI18n } from "vue-i18n";

export const translations = {
  en,
  ja,
};

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: translations,
});
export default i18n;
