import { onUnmounted, ref, Ref } from "vue";

interface useWindowHook {
  height: Ref<number>;
  width: Ref<number>;
}

export const useWindowSize = (): useWindowHook => {
  const height = ref(window.innerHeight);
  const width = ref(window.innerWidth);

  function handleResize() {
    height.value = window.innerHeight;
    width.value = window.innerWidth;
  }

  window.addEventListener("resize", handleResize);

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
  });

  return {
    height,
    width,
  };
};
