export enum CreatedVia {
  DESKTOP = "desktop",
  TABLET = "tablet",
  ADHOC = "adhoc",
  MOBILE = "mobile",
}

export interface Reservation {
  id: number;
  startsAt: Date;
  finishesAt: Date;
  title: string;
  checkedIn?: boolean;
  createdVia: CreatedVia;
  pending: boolean | null;
}
